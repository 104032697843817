body {
  --font: 15px; 
  --base-space: 1em;
  --light-green: #e0f8e6;
  --blue: #0050aa;
  --view-animation-time: 2s;  

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (min-width: 1000px) {
  body, input, button, textarea {
    font-size: var(--font);
  }
}
@media only screen and (max-width: 600px) {
  body, input, button, textarea {
    font-size: var(--font);
  }
}

textarea,
input[type=tel],
input[type=email],
input[type=password],
input[type=text] {
  border: solid 2px var(--blue);
  padding: var(--base-space) calc(var(--base-space) * 2);
  background: var(--base-light-gray);
  outline: none;  
  height: 1rem;
  box-sizing: border-box;  
  margin: 0 0 calc(var(--base-space)/2);
}
textarea:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=text]:focus {
  background: var(--base-background);
  border-color: var(--base-light-blue);
  box-shadow: var(--base-shadow);  
}
button {
  height: 1rem;
  box-sizing: border-box;
  padding: var(--base-space) calc(var(--base-space));
  border: none;
  cursor: pointer;  
  transition: all .3s;  
  outline: none;
  background-color: var(--blue);
  color: #fff;
  box-sizing: content-box;
}
button[type=submit] {
  background: var(--base-blue);
  color: var(--base-background);
}
button:hover,
button:focus {
  filter: brightness(var(--hover-brightness)); 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}