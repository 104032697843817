@font-face {
  font-family: Lidl Font Pro;
  src: url('font/LidlFontPro-SemiBold.eot');
  src: url('font/LidlFontPro-SemiBold.eot?#iefix') format("embedded-opentype"), url('font/LidlFontPro-SemiBold.woff') format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Lidl Font Pro;
  src: url('font/LidlFontPro-Regular.eot');
  src: url('font/LidlFontPro-Regular.eot?#iefix') format("embedded-opentype"), url('font/LidlFontPro-Regular.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

html, body, input, textarea, select {
  font-family: Lidl Font Pro, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: normal
}

html, body {
  min-height: 100%;
  padding: 0;
  margin: 0;
  background: var(--light-green);
  background-size: 100% auto;
  color: var(--blue);
  font-size: var(--font);
}
.main-app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  .backstage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    background: url('https://dev.code-essence.eu/lidl/media/bottom.png') bottom center no-repeat;
    background-size: contain;
    animation: appear .5s ease forwards;
    z-index: 1;

    .tree-top {
      position: absolute;
      top:0;
      width: 50%;
      height: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      animation: appear .8s ease forwards;

      &__right {
        background-image: url('https://dev.code-essence.eu/lidl/media/top-right.png');
        background-position: top right;
        right: 0;        
      }

      &__left {
        background-image: url('https://dev.code-essence.eu/lidl/media/top-left.png');
        background-position: top left;
        left: 0;
      }      
    }
  }
  .leafs {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0);    
    transition: all .3s;
    pointer-events: none;

    .leaf {
      position: fixed;        
      width: 25vw;
      height: 25vw;      
      max-width: 250px;
      max-height: 250px;
      transition: all .3s;
      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 0%;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .3s;
      }
    }
    .leaf1 {
      top: 10%;
      left: 20%;
      transform-origin: top left;
      transform: rotate(0deg);
      animation: leaf1 35s ease-in-out infinite;
      &::before {
        background: url('https://dev.code-essence.eu/lidl/media/leaf-2-glow.png') top left no-repeat;
        background-size: contain;
        opacity: 0;
      }      
      &::after {
        background: url('https://dev.code-essence.eu/lidl/media/leaf-2.png') top left no-repeat;
        background-size: contain;
      }
    }
    .leaf2 {
      bottom: 10%;
      left: 12%;
      transform-origin: bottom left;
      transform: rotate(0deg);
      animation: leaf2 25s ease-in-out infinite;
      animation-delay: 1s;
      &::before {
        background: url('https://dev.code-essence.eu/lidl/media/leaf-1-glow.png') top left no-repeat;
        background-size: contain;
        opacity: 0;
      }      
      &::after {
        background: url('https://dev.code-essence.eu/lidl/media/leaf-1.png') top left no-repeat;
        background-size: contain;
      }      
    }
    .leaf3 {
      top: 20%;
      right: 20%;
      background-size: contain;
      transform-origin: top right;
      transform: rotate(0deg);
      animation: leaf3 25s ease-in-out infinite;
      animation-delay: 1s;
      &::before {
        background: url('https://dev.code-essence.eu/lidl/media/leaf-3-glow.png') top left no-repeat;
        background-size: contain;
        opacity: 0;
      }      
      &::after {
        background: url('https://dev.code-essence.eu/lidl/media/leaf-3.png') top left no-repeat;
        background-size: contain;
      }       
    }

    &__active {
      z-index: 4;
      background-color: rgba($color: #000000, $alpha: .3);
      pointer-events: all;

      .leaf {
        cursor: pointer;
        animation: none;        
        &:hover {
          &::before {
            opacity: 1;            
            transform: scale(1.3);
          }              
        }
        &::before {
          opacity: .7;
          transform: scale(1.1);          
        }        
      }
      .leaf1 {
        top: 20%;
        left: 26%;
        transform: rotate(-15deg);

      }
      .leaf2 {
        bottom: 20%;
        left: 30%;
        transform: rotate(-15deg);
      }
      .leaf3 {
        top: 25%;
        right: 22%;
      }   
    }    
  }
  .app-container {
    flex: 1;
    position: relative;
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    background: #fff;
    border-radius: 100%;    
    padding: calc(var(--base-space)*4);
    text-align: center;
    width: max(30vh, 450px);
    height: max(30vh, 450px);
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 2.5rem;
      margin: 0;
    }

    p {
      font-size: 1.5rem;
      margin: var(--base-space) 0 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(var(--base-space)*2);
      left: calc(var(--base-space)*2);
      width: calc(100% - var(--base-space)*4);
      height: calc(100% - var(--base-space)*4);
      border: dashed 4px var(--blue);
      border-radius: 100%;
      animation: rotate 15s infinite linear;
    }

    &.bounce-out {
      animation: popOut var(--view-animation-time) cubic-bezier(0.775, -0.600, 0.000, 1.605) forwards;
    }     
  }
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;

  > div {
    background: #fff url('https://dev.code-essence.eu/lidl/media/ring.png') center center no-repeat;
    background-size: cover;
    border-radius: 100%;    
    padding: calc(var(--base-space)*4);
    text-align: center;
    width: 35vmin;
    height: 35vmin;
    min-width: 360px;
    min-height: 360px;    
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: scale(0%);

    h1 {
      font-size: 2.5rem;
      margin: 0;
    }

    &.bounce-in {
      animation: popIn var(--view-animation-time) cubic-bezier(.31,2,.84,.6) forwards;

      .letter-in {
        > span {
          display: inline-block;
          position: relative;
          animation: letterIn .6s linear forwards;
          opacity: 0;
          transform: rotate(107deg) scale(.2) translateX(570%) translateY(150%);          
          @for $i from 1 through 20 {
            $delay: $i / 10 + 1;      
            &:nth-child(#{$i}) {
              animation-delay: #{$delay}s;
            }
          }  
        }
      }

    }
    .welcome {
      h1 {
        font-size: 5rem;
        line-height: 5rem;
      }
    }
    .reg {

      padding: 0 0.8rem;

      p {
        font-size: 1rem;
        margin: var(--base-space/2) 0 0;
      }    
    }

  }
}

.fade-stack {
  > * {
    animation: fadeIn .5s ease forwards;
    opacity: 0;
    @for $i from 1 through 20 {
      $delay: $i / 10 + 1;      
      &:nth-child(#{$i}) {
        animation-delay: #{$delay}s;
      }
    }
  }
 
}



@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
@keyframes popOut {
  from {transform: scale(100%);}
  5% {transform: scale(110%);}
  to {transform: scale(0%);}
}
@keyframes popIn {
  from {transform: scale(0%);}
  90% {transform: scale(110%);}
  to {transform: scale(100%);}
}
@keyframes appear {
  from {
    opacity: 0;
    transform: scale(110%);
  }
  to {
    opacity: 1;
    transform: scale(100%);
  }
}

@keyframes leaf1 {
  50% {transform: rotate(17deg);}
}
@keyframes leaf2 {
  50% {transform: rotate(-12deg);}
}
@keyframes leaf3 {
  50% {transform: rotate(12deg);}
}
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes letterIn {
  from {
    opacity: 0;
    transform: rotate(107deg) scale(.2) translateX(570%) translateY(150%);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1) translateX(0%) translateY(0%);    
  }
}